/*
 *  User Profile Service
 *
 *  ready ( callback: Function(user_profile) ):
 *    when ready, call callback accepting a argument contain user profile
 *
 *  It can be used in this way to easily migrate from user_profile:
 *   + wProfile.ready(function(user_profile) {
 *       // some code used user_profile, like:
 *       console.log(user_profile)
 *   + });
 *
 *  Use wProfile.profile is another choise if ready or not isn't important
 *  or it's believed to be ready when using.
 */
import docCookies from '../vendors/docCookies';

var wProfile = {
  _inited: false,
  _loading: false,
  _readyCallback: [],
  profile: null,
  _bot_access: false,

  ready: function(fn) {
    if (this._inited) return fn(this.profile);
    this._readyCallback.push(fn);

    // load detailed user data only when needed
    // since many page only need id
    if (!this._loading) this._getUserData();
  },

  _init: function() {
    var me = this;

    me._bot_access = (navigator.userAgent && navigator.userAgent.match(/bot/i));

    if (!me._bot_access) {
      var requestSessionCookie = new XMLHttpRequest();
      requestSessionCookie.open('GET', 'https://womany.net/users/create_session', true);
      requestSessionCookie.send();
    }

    var userId = docCookies.getItem('_womany_net_bypass');
    me.profile = { // preset data
      id: parseInt(userId),
      login: !!userId
    };
  },

  _getUserData: function() {
    var me = this;

    me._loading = true;
    $.ajax({
      url: womany.api + '/user',
      xhrFields: { withCredentials: true },
      success: function(res) {
        me.profile = res.data.user;
        me._readyCallback.forEach(function(fn) {
          fn(me.profile);
        });
        me._inited = true;

        var Dt = new Date(Date.now() + (86400000 * 365));
        docCookies.setItem('ws', me.profile.sid7, Dt, '/');
      },
      error: function() {
        me.profile = {
          login: false,
          womanyor: false,
        };
        me._readyCallback.forEach(function(fn) {
          fn(me.profile);
        });
        me._inited = true;
      }
    });
  }
};
wProfile._init();
window.wProfile = wProfile;
